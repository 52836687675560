const filters_config = {
    status:{
      show: true,
      options: [
        { value: 'success', label: 'Оплачен', selected: true},
        { value: 'error', label: 'Ошибка'},
        { value: 'processing', label: 'В процессе', selected: true},
        { value: 'initial', label: 'Новый', selected: true},
        { value: 'partial_payment', label: 'Частично опл.', selected: true},
      ]
    },
    paymentMethodNames:{
      show: true,
      options: [
        { value: 'credit', label: 'Кредит/Рассрочка'},
        { value: 'card', label: 'Банковская карта'},
      ]
    },
  }

  export default filters_config
