function getStatusBadge(filters_config, current_value){
    if (filters_config !== null) {
//        let statusOptions = [...filters_config.status.options, ...filters_config.status_loans.options]
        let statusOptions = filters_config.status.options
        let output = '<span class="badge badge-light">'+current_value+'</span>'
        statusOptions.forEach(element => {
          let st = element.value.split(',')
          if (st.includes(current_value)){
            if (element.label === 'Кредит выдан'){
              output = '<span class="badge badge-success">'+element.label+'</span>'
            }
            if (element.label === 'Отказ клиента' || element.label === 'Возврат'
              || element.label === 'Отказ кредиторов' || element.label === 'Финальный отказ'){
              output = '<span class="badge badge-danger">'+element.label+'</span>'
            }
            if (element.label === 'На подписании'){
              output = '<span class="badge badge-info">'+element.label+'</span>'
            }
            if (element.label === 'Новая заявка' || element.label === 'Заполнение анкеты'
              || element.label === 'На рассмотрении'){
              output = '<span class="badge badge-secondary">'+element.label+'</span>'
            }
            if (element.label === 'Средства переведены'){
              output = '<span class="badge badge-warning">'+element.label+'</span>'
            }

          }
        })
        return output
    } else {
        return ''
    }
}
export default getStatusBadge
