<template>
      <div class="card-div mb-4 invoices-wrapper with-nav-tabs">
        <b-form @submit.prevent="onSubmit">
          <div class="back">
            <router-link :to="back()">
              <img src="@/assets/images/apple-keyboard-control.png" alt="Go back" width="14" height="14">
              <span class="back-link">Вернуться</span>
            </router-link>
            <div class="float-right ml-auto">
              <a class="desktop-layer" href="https://mndr.in/invoices" target="_blank">Инструкция по настройке и использованию</a>
              <a class="mobile-layer" href="https://mndr.in/invoices" target="_blank">Инструкция</a>
            </div>
          </div>

          <div class="settings-block d-none">
            <div class="header mb-0">
              <div class="header-text">
                <h5>Данные заказа</h5>
              </div>
            </div>
<!--            <b-form-group class="payment-input mt-0 mb-4">-->
<!--              <b-form-checkbox v-model="settings.basic_settings.email.hide" switch>Запрашивать email-->
<!--                <i v-b-tooltip.hover title="Если эта настройка активна, то для выставления счета понадобится по умолчанию указывать Email клиента">-->
<!--                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">-->
<!--                </i>-->
<!--              </b-form-checkbox>-->
<!--              <b-form-checkbox v-model="settings.basic_settings.phone.hide" switch>Запрашивать номер телефона-->
<!--                <i v-b-tooltip.hover title="Если эта настройка активна, то для выставления счета понадобится по умолчанию указывать телефон клиента">-->
<!--                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">-->
<!--                </i>-->
<!--              </b-form-checkbox>-->
<!--            </b-form-group>-->
            <b-form-group class="payment-input mt-0 mb-4">
              <b-form-checkbox v-model="settings.basic_settings.order.requestPayerOrderId" switch>Запрашивать у клиента номер заказа
                <i v-b-tooltip.hover title="Если эта настройка активна, то для выставления счета клиенту понадобится указывать номер заказа">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </b-form-checkbox>
              <b-form-checkbox v-model="settings.basic_settings.order.requestPayerEmail" switch>Запрашивать почту у клиента
                <i v-b-tooltip.hover title="Если эта настройка активна, то для выставления счета клиенту понадобится указывать email">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </b-form-checkbox>
              <b-form-checkbox v-model="settings.basic_settings.order.requestPayerPhone" switch>Запрашивать телефон у клиента
                <i v-b-tooltip.hover title="Если эта настройка активна, то для выставления счета клиенту понадобится по указывать телефон">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </b-form-checkbox>
              <b-form-checkbox v-model="settings.basic_settings.order.allowPayerToSpecifyEmailOrPhone" switch>Клиент может указать email ИЛИ номер телефона
                <i v-b-tooltip.hover title="Если эта настройка активна, то клиент может указать email ИЛИ номер телефона">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </b-form-checkbox>
            </b-form-group>
          </div>

          <div class="settings-block">
            <div class="header mb-3">
              <div class="header-text">
                <h5>Способы оплаты</h5>
              </div>
            </div>

            <div class="w-100 mt-n3">
              <b-form-checkbox
                v-model="settings.payment_methods_settings.credit.enable"
                switch
                :disabled="!isVisible()"
                :class="{ 'text-danger': !isVisible() }"
              >
                Кредит / Рассрочка{{ !isVisible() ? ' (недостаточно прав)' : '' }}
              </b-form-checkbox>

              <div v-if="settings.payment_methods_settings.credit.enable && isVisible()" class="mt-3">
                <b-form-group class="payment-input d-block">
                  <legend>Кредитный продукт
                    <i v-b-tooltip.hover title="Выберите кредитные продукты, которые будут по умолчанию доступны клиентам при выставлении счета">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                    </i>
                  </legend>
                  <b-form-select :options="hl_merchants_options" v-model="settings.payment_methods_settings.credit.product_id" @change="handleMerchantIdChange"></b-form-select>
                </b-form-group>
                <b-form-group class="payment-input d-block">
                  <legend>Доступные сроки
                    <i v-b-tooltip.hover title="Выберите срок(и) заявок на кредит / рассрочку, которые будут по умолчанию доступны клиентам при выставлении счета.">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                    </i>
                  </legend>
                  <div class="d-flex justify-content-between">
                    <multiselect  id="some" v-model="term_in_months"
                                  :options="term_in_months_options_ms"
                                  :multiple="true"
                                  :searchable="false"
                                  track-by="value"
                                  label="text"
                                  :deselect-label="$i18n.t('service.remove')"
                                  :select-label="$i18n.t('service.add')"
                                  :selected-label="$i18n.t('service.optionSelected')"
                                  placeholder="Выберите сроки для показа в модуле"></multiselect>
                  </div>
                </b-form-group>
              </div>

              <b-form-checkbox v-model="settings.payment_methods_settings.card.enable" switch>Карта РФ</b-form-checkbox>

              <div v-show="settings.payment_methods_settings.card.enable" class="mt-3">
                <b-form-group class="payment-input d-block">
                  <legend>Выберите проект
                    <i v-b-tooltip.hover title="Укажите merchant ID">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                    </i>
                  </legend>
                  <b-form-select :options="get_merchants" v-model="settings.payment_methods_settings.card.merchant_id"></b-form-select>
                </b-form-group>
              </div>

              <b-form-checkbox v-model="settings.payment_methods_settings.card2.enable" switch>Иностранная карта</b-form-checkbox>

              <div v-show="settings.payment_methods_settings.card2.enable" class="mt-3">
                <b-form-group class="payment-input d-block">
                  <legend>Выберите проект
                    <i v-b-tooltip.hover title="Укажите merchant ID">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                    </i>
                  </legend>
                  <b-form-select :options="get_merchants" v-model="settings.payment_methods_settings.card2.merchant_id"></b-form-select>
                </b-form-group>
              </div>

            </div>
          </div>

          <div class="settings-block">
            <div class="header mb-0">
              <div class="header-text">
                <h5>Дополнительные настройки</h5>
              </div>
            </div>

            <b-form-group class="payment-input mt-3">
              <legend>Ссылка на оферту
                <i v-b-tooltip.hover title="Добавьте ссылку на вашу оферту с клиентом, если хотите, чтобы она отображалась на платежной странице">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </legend>
              <b-form-input v-model="settings.mandatory_fields_settings.offer_link" />
            </b-form-group>

            <b-form-group class="payment-input">
              <legend>Редирект в случае успеха
                <i v-b-tooltip.hover title="Укажите ссылку страницы, на которую клиент будет перенаправлен в случае успешной оплаты">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </legend>
              <b-form-input v-model="settings.mandatory_fields_settings.redirect_url" />
            </b-form-group>

            <b-form-group class="payment-input mb-3">
              <legend>Редирект в случае ошибки
                <i v-b-tooltip.hover title="Укажите ссылку страницы, на которую клиент будет перенаправлен в случае, если оплатить не удалось">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </legend>
              <b-form-input v-model="settings.mandatory_fields_settings.redirect_failed_url" />
            </b-form-group>

            <b-form-group class="payment-input file-group-container mb-0 pb-5 mt-4">
              <legend>
                Добавить логотип
                <i v-b-tooltip.hover title="Загрузите логотип в формате PNG/JPEG/SVG. Размер файла не должен превышать 10 МБ.  Форматы: PNG, JPG, SVG">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                </i>
              </legend>
              <div class="align-center" v-if="!settings.mandatory_fields_settings.logo">
                <div class="file-drop-area">
                  <span class="file-message">Загрузите логотип</span>
                  <input class='w-100 file-input' type="file" accept="image/jpeg, image/gif, image/png" id="logoimage" @change="onLogoChange"/>
                </div>
              </div>
              <div v-else class="">
                <div class="align-center" style="max-height: 100px;">
                  <img class='' :src="settings.mandatory_fields_settings.logo"  alt="image"/>
                  <button class="btn-delete" @click="deleteImageLogo()"></button>
                </div>
              </div>
            </b-form-group>

            <b-form-checkbox class="payment-input" v-model="ext_field_visible" @change="handleExtFieldVisibleChange" switch>Добавить дополнительные поля</b-form-checkbox>
            <div v-show="ext_field_visible">
              <b-table :fields="fields_optional_info" :items="settings.additional_fields_settings" class="w-100">
                <template #cell(name)="data">
                  <b-form-input v-model="data.item.name" :value="data.item.name"/>
                </template>
                <template #cell(value)="data">
                  <b-form-input v-model="data.item.value" :value="data.item.value"/>
                </template>
                <template #cell(delete)="data">
                  <div class="d-flex justify-content-end align-items-center">
                    <img :src="require('@/assets/images/icon-delete.svg')" alt="delete icon" class="lh-36" @click='del(data.index)' />
                  </div>
                </template>
              </b-table>
              <b-button block variant="outline-primary" @click='add()'>Добавить поле</b-button>
            </div>

          </div>

          <div class="settings-block">
            <div class="header mb-0">
              <div class="header-text">
                <h5>Настройки фискализации
                  <i v-b-tooltip.hover title="Для тестового проекта касса недоступна">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="14" height="14">
                  </i>
                </h5>
              </div>
            </div>
            <div class="w-100">
              <b-form-group class="payment-input mb-0">
                <b-form-checkbox v-model="settings.fiscalization_settings.enable" switch>
                  Требуется фискализация
                </b-form-checkbox>
              </b-form-group>
<!--              Disabled-->
              <div class="d-none">
                <div v-if="settings.fiscalization_settings.enable">
                  <b-form-group class="payment-input mt-4">
                    <legend class="">Система налогообложения</legend>
                    <b-form-select v-model="settings.fiscalization_settings.taxationSystem" :options="optionsTaxationSystem"></b-form-select>
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Ставка НДС</legend>
                    <b-form-select v-model="settings.fiscalization_settings.vat" :options="optionsVat"></b-form-select>
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Способ расчета</legend>
                    <b-form-select v-model="settings.fiscalization_settings.calculationMethod" :options="optionscalculationMethod"></b-form-select>
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Предмет расчета</legend>
                    <b-form-select v-model="settings.fiscalization_settings.paymentSubject" :options="optionspaymentSubject"></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-12">
              <b-button :disabled="apiResponseReceived" type="submit" variant="primary" block>
                <span v-if="apiResponseReceived" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ apiResponseReceived ? ' Сохранение...' : 'Сохранить' }}
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {getTermOptions} from "../../../plugins/utils";
function validURL(str) {
  let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}
function isNumeric(n) {
  return /^-?\d*[.,]?\d*$/.test(n);
}
const default_options = [
  {value: 'none', text: '- не выбрано -'},
]
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      validationErrors: {},
      term_in_months: [],
      hl_merchants_options: default_options,
      term_in_months_options: default_options,
      min_order_amount: 1000,
      max_order_amount: 500000,
      optionsTaxationSystem: [
        {value: 'Common', text: 'Общая (ОСН)'},
        {value: 'Simplified', text: 'Упрощенная (УСН) "Доходы"'},
        {value: 'SimplifiedMinusOutlay', text: 'Упрощенная (УСН) "Доходы минус расходы"'},
        {value: 'UnifiedImputedIncome', text: 'Единый налог на вмененный доход (ЕНВД)'},
        {value: 'UnifiedAgricultural', text: 'Единый сельскохозяйственный налог (ЕСХН)'},
        {value: 'Patent', text: 'Патентная (ПСН)'}
      ],
      optionsVat: [
        {value: 'None', text: 'Без НДС'},
        {value: 'Vat0', text: '0%'},
        { value: 'Vat5', text: '5%' },
        { value: 'Vat7', text: '7%' },
        {value: 'Vat10', text: '10%'},
        //{value: 'Vat18', text: 'НДС по ставке 18%'},
        {value: 'Vat20', text: '20%'}
      ],
      optionscalculationMethod: [
        {value: 'FULL_PAY', text: 'Полная оплата, в том числе с учётом аванса (предварительной оплаты) в момент передачи предмета расчёта'},
        {value: 'PREPAY_FULL', text: 'Полная предварительная оплата до момента передачи предмета расчёта'},
        {value: 'PREPAY_PARTIAL', text: 'Частичная предварительная оплата до момента передачи предмета расчёта'},
        {value: 'AVANS', text: 'Аванс'},
        {value: 'PARTIAL_SETTLEMENT_AND_CREDIT', text: 'Частичная оплата предмета расчёта в момент его передачи с последующей оплатой в кредит'},
        {value: 'TRANSFER_ON_CREDIT', text: 'Передача предмета расчёта без его оплаты в момент его передачи с последующей оплатой в кредит'},
        {value: 'CREDIT_PAYMENT', text: 'Оплата предмета расчёта после его передачи с оплатой в кредит (оплата кредита)'}
      ],
      optionspaymentSubject: [
        {value: 'SERVICE', text: 'Услуга'},
        {value: 'AGENCY', text: 'Агентское вознаграждение'},
        {value: 'COMPOUND_SUBJECT', text: 'Составной предмет расчета'},
        {value: 'EXCISABLE_PRODUCT', text: 'Подакцизный товар'},
        {value: 'GAMBLING_RATE', text: 'Ставка азартной игры'},
        {value: 'GAMBLING_WIN', text: 'Выигрыш азартной игры'},
        {value: 'INSURANCE_CONTRIBUTIONS', text: 'Страховые взносы'},
        {value: 'JOB', text: 'Работа'},
        {value: 'LOTTERY_TICKET', text: 'Лотерейный билет'},
        {value: 'LOTTERY_WIN', text: 'Выигрыш лотереи'},
        {value: 'NON_OPERATING_INCOME', text: 'Внереализационный доход'},
        {value: 'OTHER_SUBJECT', text: 'Иной предмет расчета'},
        {value: 'PAYMENT', text: 'Платеж'},
        {value: 'PLEDGE', text: 'Залог'},
        {value: 'PRODUCT', text: 'Товар'},
        {value: 'PROPERTY_LAW', text: 'Имущественное право'},
        {value: 'PROVISION_RID', text: 'Представление РИД'},
        {value: 'RESORT_FEE', text: 'Курортный сбор'},
        {value: 'TRADE_FEE', text: 'Торговый сбор'}
      ],
      enableSaveModule: true,
      ext_field_visible: false,
      fields_optional_info: [{key: 'name', label: 'Наименование'}, {key: 'value', label: 'Подсказка'}, {key: 'delete', label: '', class: 'align-center'}],
      settings: {
        new: true,
        client_id: null,
        is_active: true,
        country_settings: {},
        basic_settings: {phone: {hide: false, value: ''}, email: {hide: false, value: ''}, order: {requestPayerOrderId: false, requestPayerEmail: false, requestPayerPhone: false, allowPayerToSpecifyEmailOrPhone: false}},
        payment_methods_settings: {credit: {enable: false, product_id: '', term: [], api_key: '', credit: false, gp: false, segment: '', merchant: {}}, card: {enable: false, merchant_id: '', merchant_name: ''}, card2: {enable: false, merchant_id: '', merchant_name: ''}},
        mandatory_fields_settings: {logo: '', redirect_url: 'https://mandarin.io/', redirect_failed_url: 'https://mandarin.io/', offer_link: 'https://mandarin.io/conditions'},
        additional_fields_settings: [],
        fiscalization_settings: {enable: false, taxationSystem: 'Common', vat: 'None', calculationMethod: 'FULL_PAY', paymentSubject: 'SERVICE'},
        security_settings: {},
        limit_settings: {},
        integration_settings: {},
        advanced_settings: {},
      },
    };
  },
  computed: {
    get_merchants() {
      // Compare merchants and merchants_name
      let merchants = this.$store.state.account.merchants // Example [604,788,974]
      let merchants_name = this.$store.state.account.merchants_name // Example ["ООО Ромашка","ООО ПСП0","ООО ПСП"]
      let options = [{value: 'none', text: '- не выбрано -'}]
      if(Array.isArray(merchants) && Array.isArray(merchants_name)) {
        merchants.forEach( (merchant, index) => {
          if (merchant !== null && merchants_name[index] !== null) {
            options.push({value: merchant, text: `${merchant} ${merchants_name[index]}`})
          }
        })
      }
      return options;
    },
    apiResponseReceived () {
      return this.$store.state.invoices.isLoading;
    },
    term_in_months_options_ms() {
      // Создаем копию массива, чтобы не изменять оригинальный
      let options = [...this.term_in_months_options];

      // Если длина больше 1 и первый элемент 'none', удаляем его
      if (options.length > 1 && options[0].value === 'none') {
        options.shift();
      }

      // Используем Set для отслеживания уже встреченных значений value
      const seen = new Set();

      // Фильтруем массив, оставляя только первые вхождения каждого value
      return options.filter(option => {
        if (seen.has(option.value)) {
          return false; // Пропускаем, если значение уже встречалось
        } else {
          seen.add(option.value); // Добавляем значение в Set
          return true; // Сохраняем опцию
        }
      });
    },
  },
  methods: {
    isVisible() {
      // console.log('IS VISIBLE', this.checkRolePresence('clients.life_merchant.basic'));
      return this.checkRolePresence('clients.life_merchant.basic')
    },
    handleExtFieldVisibleChange(newVal) {
      this.ext_field_visible = newVal;
      if (newVal && this.settings.additional_fields_settings.length === 0) this.settings.additional_fields_settings = [];
    },
    async handleMerchantIdChange(newVal) {
      let options = [{value: 'none', text: '- не выбрано -'}]
      let api_key = ""
      let credit = false
      let gp = false
      let segment = ''
      if (newVal !== 'none') {
        let merchants = this.$store.state.account.hl_merchants
        let merchant = merchants.find(obj => obj.merchant_id === newVal);
        if (merchant) {
          this.settings.payment_methods_settings.credit.merchant = merchant
          if (merchant.min_order_amount !== undefined &&  isNumeric(merchant.min_order_amount)) this.min_order_amount = parseFloat(merchant.min_order_amount.replace(',', '.'));
          if (merchant.max_order_amount !== undefined && isNumeric(merchant.max_order_amount)) this.max_order_amount = parseFloat(merchant.max_order_amount.replace(',', '.'));
          api_key = merchant.api_key

          if (merchant.use_billing_pricing_models && merchant.use_billing_pricing_models === true) {
            options = await this.$store.dispatch('invoices/getBillingPricingModels', {
              clientId: this.$store.state.account.mandarin.client_id,
              merchantId: merchant.mandarin_merchant_id
            })
          } else {
            let result = getTermOptions(merchant, gp)
            options = result.options
            gp = result.gp
          }

          credit = !merchant.calc_discount7Seconds;
          segment = merchant.segment ? merchant.segment : '';
        }
      }
      this.term_in_months_options = options;
      this. term_in_months = [];
      this.settings.payment_methods_settings.credit.api_key = api_key;
      this.settings.payment_methods_settings.credit.credit = credit;
      this.settings.payment_methods_settings.credit.gp = gp;
      this.settings.payment_methods_settings.credit.segment = segment;
    },
    back () {
      return '/invoices';
    },
    add() {
      this.settings.additional_fields_settings.push({
        'name': '',
        'value': ''
      });
    },
    del(index) {
      this.settings.additional_fields_settings.splice(index, 1);
    },
    deleteImageLogo() {
      this.settings.mandatory_fields_settings.logo = "";
    },
    onLogoChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      let file = files[0];

      // Проверка формата файла
      const allowedFormats = ['image/png', 'image/jpeg', 'image/svg+xml'];
      if (!allowedFormats.includes(file.type)) {
        this.$toast.error('Только изображения в форматах PNG, JPG и SVG допустимы для загрузки.');
        return;
      }

      // Проверка размера файла (до 10 МБ)
      if (file.size > 10 * 1024 * 1024) {
        this.$toast.error('Файл превышает 10 Мб.');
        return;
      }

      this.createImage(file, true);
    },
    createImage: function(file, lg) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = function(e) {
        vm.settings.mandatory_fields_settings.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    validateAll() {
      this.validationErrors = {}; // Сброс предыдущих ошибок валидации

      // 1. Проверка выбран ли хотя бы один способ оплаты
      if (!this.settings.payment_methods_settings.credit.enable && !(this.settings.payment_methods_settings.card.enable || this.settings.payment_methods_settings.card2.enable)) {
        this.validationErrors.paymentMethod = 'Выберите хотя бы один способ оплаты';
      }

      // 2. Проверка кредита
      if (this.settings.payment_methods_settings.credit.enable) {
        if (!this.settings.payment_methods_settings.credit.product_id || this.term_in_months.length === 0) {
          this.validationErrors.credit = 'Для кредита выберите мерчант и установите срок';
        }
      }

      // 3. Проверка карты
      if (this.settings.payment_methods_settings.card.enable) {
        if (!this.settings.payment_methods_settings.card.merchant_id) {
          this.validationErrors.card = 'Для карты выберите мерчанта';
        }
      }

      // 3. Проверка карты 2
      if (this.settings.payment_methods_settings.card2.enable) {
        if (!this.settings.payment_methods_settings.card2.merchant_id) {
          this.validationErrors.card = 'Для карты выберите мерчанта';
        }
      }

      // 4. Проверка дополнительных настроек
      if (this.areAdditionalSettingsEmpty()) {
        this.validationErrors.additionalSettings = 'Заполните все дополнительные настройки';
      }

      // 5. Проверка дополнительных полей
      if (this.settings.additional_fields_settings.some(field => !field.name)) {
        this.validationErrors.additionalFields = 'Все дополнительные поля должны иметь название';
      }

      // 6. Проверка фискализации
      if (this.settings.fiscalization_settings.enable && this.areFiscalizationSettingsIncomplete()) {
        this.validationErrors.fiscalization = 'Заполните все поля настроек фискализации';
      }
    },
    isFormValid() {
      return Object.keys(this.validationErrors).length === 0;
    },
    areAdditionalSettingsEmpty() {
      // Проверка, пустые ли дополнительные настройки
      return !this.settings.mandatory_fields_settings.offer_link ||
        !this.settings.mandatory_fields_settings.redirect_url ||
        !this.settings.mandatory_fields_settings.redirect_failed_url;
    },
    areFiscalizationSettingsIncomplete() {
      // Проверка, все ли поля фискализации заполнены
      const fs = this.settings.fiscalization_settings;
      return !fs.taxationSystem || !fs.vat || !fs.calculationMethod || !fs.paymentSubject;
    },
    onSubmit() {
      if (!this.ext_field_visible) this.settings.additional_fields_settings = []

      this.validateAll();

      if (!this.isFormValid()) {
        const errorMessages = Object.values(this.validationErrors).join('\n');
        this.$toast.error(`Пожалуйста, исправьте следующие ошибки:\n${errorMessages}`);
        return;
      }

      this.settings.client_id = this.$store.state.account.mandarin.client_id;
      this.settings.payment_methods_settings.credit.term = this.term_in_months

      this.settings.basic_settings.phone.hide = !this.settings.basic_settings.order.requestPayerPhone
      this.settings.basic_settings.email.hide = !this.settings.basic_settings.order.requestPayerEmail

      this.$store.dispatch('invoices/saveAccountSettings', { settings: this.settings })
        .then(() => {
          this.$toast.success('Настройки успешно сохранены.');
          this.settings = this.$store.state.invoices.accountSettings;
          this.$router.push({ name: 'Invoices' });
        })
        .catch(error => {
          console.error('Error saving account settings:', error);
          this.$toast.error('Ошибка при сохранении настроек: ' + error);
        })
    },
  },
  watch: {
    '$store.state.invoices.accountSettings': {
      immediate: true,
      handler(val) {
        // console.log(val);
        if (val && val.client_id) {
          this.settings = this.$store.state.invoices.accountSettings;
          if (this.settings.additional_fields_settings.length > 1 || (this.settings.additional_fields_settings.length && this.settings.additional_fields_settings[0].name !== '')) this.ext_field_visible = true;
          if (this.settings.basic_settings.order === undefined) this.settings.basic_settings.order = {requestPayerOrderId: false, requestPayerEmail: false, requestPayerPhone: false, allowPayerToSpecifyEmailOrPhone: false};
          if (this.settings.payment_methods_settings && this.settings.payment_methods_settings.card2 === undefined) this.$set(this.settings.payment_methods_settings, 'card2', {enable: false, merchant_id: '', merchant_name: ''})
        }
      }
    },
    '$store.state.account.hl_merchants': {
      immediate: true,
      handler() {
        let merchants = this.$store.state.account.hl_merchants
        let options = [{value: 'none', text: '- не выбрано -'},]
        let options_term = [{value: 'none', text: '- не выбрано -'},]
        if(Array.isArray(merchants)) {
          merchants.forEach( async (merchant) => {
            if (merchant.is_active) {
              options.push({value: merchant.merchant_id, text: merchant.full_name})
              if (merchant.merchant_id === this.settings.payment_methods_settings.credit.product_id) {
                this.settings.payment_methods_settings.credit.merchant = merchant
                if (merchant.min_order_amount !== undefined && isNumeric(merchant.min_order_amount)) this.min_order_amount = parseFloat(merchant.min_order_amount.replace(',', '.'));
                if (merchant.max_order_amount !== undefined && isNumeric(merchant.max_order_amount)) this.max_order_amount = parseFloat(merchant.max_order_amount.replace(',', '.'));

                if (merchant.use_billing_pricing_models && merchant.use_billing_pricing_models === true) {
                  options_term = await this.$store.dispatch('invoices/getBillingPricingModels', {
                    clientId: this.$store.state.account.mandarin.client_id,
                    merchantId: merchant.mandarin_merchant_id
                  })
                } else {
                  let result = getTermOptions(merchant, this.settings.payment_methods_settings.credit.gp)
                  options_term = result.options
                  this.settings.payment_methods_settings.credit.gp = result.gp
                }

                this.term_in_months_options = options_term;
              }
            }
          })
        }
        this.hl_merchants_options = options;
        this. term_in_months = this.settings.payment_methods_settings.credit.term;
      }
    },
    'settings.payment_methods_settings.card.merchant_id': {
      immediate: true,
      handler(newVal) {
        // add merchant_name to settings
        // console.log(newVal);
        let merchants_name = this.$store.state.account.merchants_name
        // find index of merchant_id in merchants
        let index = this.$store.state.account.merchants.indexOf(newVal);
        if (newVal !== null && merchants_name[index] !== null) {
          this.settings.payment_methods_settings.card.merchant_name = merchants_name[index]
        }
      }
    },
    'settings.payment_methods_settings.card2.merchant_id': {
      immediate: true,
      handler(newVal) {
        // console.log(newVal);
        let merchants_name = this.$store.state.account.merchants_name
        // find index of merchant_id in merchants
        let index = this.$store.state.account.merchants.indexOf(newVal);
        if (newVal !== null && merchants_name[index] !== null) {
          this.settings.payment_methods_settings.card2.merchant_name = merchants_name[index]
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
