var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('NavTabs'),_c('b-card',{staticClass:"card-small with-nav-tabs",attrs:{"id":"receipts"}},[_c('Filters'),_c('PagerComponent'),(_vm.loadingData)?_c('Loader',{attrs:{"height":600,"size":3}}):_c('div',{staticClass:"main-table-wrapper"},[_c('b-table',{staticClass:"text-nowrap",attrs:{"striped":"","hover":"","selectable":"","select-mode":"single","fields":_vm.fields,"items":_vm.dataTable},on:{"row-clicked":_vm.selectItem},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.field.label)}})]}},{key:"cell(cs1)",fn:function(data){return [_c('clipboard',{attrs:{"data":data.item.mandarinId}}),_vm._v(" "+_vm._s(data.item.mandarinId)+" "),(data.item.extraData.FiscalDocument)?_c('div',[_c('clipboard',{attrs:{"data":data.item.extraData.FiscalDocument}}),_vm._v(" "+_vm._s(data.item.extraData.FiscalDocument)+" ")],1):_vm._e()]}},{key:"cell(customer)",fn:function(data){return [(data.item.customerPhone)?_c('clipboard',{attrs:{"data":data.item.customerPhone}}):_vm._e(),_vm._v(" "+_vm._s(data.item.customerPhone)),_c('br'),(data.item.customerEmail)?_c('clipboard',{attrs:{"data":data.item.customerEmail}}):_vm._e(),_vm._v(" "+_vm._s(data.item.customerEmail)+" ")]}},{key:"cell(rrn)",fn:function(data){return [(
                data.item.extraData.Request &&
                data.item.extraData.Request.items &&
                data.item.extraData.Request.items[0] &&
                data.item.extraData.Request.items[0].calculationMethod
              )?_c('div',[_vm._v(" "+_vm._s(_vm.getCalculationMethodName( data.item.extraData.Request.items[0].calculationMethod ))+" ")]):_c('br'),_vm._v(" "+_vm._s(_vm.getActionType(data.item.actionType))+" ")]}},{key:"cell(mid)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.merchantId)),_c('br'),_vm._v(" "+_vm._s(_vm.properDT(data.item.createdAt))+" ")]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.extraData.FiscalAmount ? _vm.properM(data.item.extraData.FiscalAmount) : _vm.properM(data.item.amount))),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getStatusBadge(data.item.mandarinStatus))}})]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{ref:"dropdown",attrs:{"text":"Действия","variant":"primary","right":""}},[_c('b-dropdown-item',{attrs:{"disabled":(!data.item.extraData.Response ||
                    !data.item.extraData.Response.Raw) &&
                  data.item.providerType !== 'Bifit'},on:{"click":function($event){return _vm.printReceipt({
                    dk:
                      data.item.providerType === 'DigitalKassa'
                        ? data.item.providerId
                        : null,
                    other: data.item.extraData.Response
                      ? data.item.extraData.Response.Raw
                      : null,
                  })}}},[_vm._v(" Распечатать чек ")])],1)]}}])}),(!_vm.haveResults)?_c('div',{staticStyle:{"min-height":"400px","text-align":"center","padding-top":"150px"}},[_vm._v(" Данные за указанный период с учетом выбранных фильтров отсутствуют. ")]):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"fixed-extend",attrs:{"cols":"3"}},[_c('ExtendReceipts',{attrs:{"item":_vm.extendItem}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }