export default {
  namespaced: true,
  state: {
    receipts: [],
    urls: {
      receipts: "public/receipts",
    },
  },
  mutations: {
    updateReceipts(state, value) {
      state.receipts = value
    },
  },
  actions: {
    prepareFilterByString(context) {
      let filterByString = ""
      if (context.rootState.filtersStd.dateFrom) {
        filterByString +=
          "&created_start_date>=" + context.rootState.filtersStd.dateFrom
      }
      if (context.rootState.filtersStd.dateTo) {
        filterByString +=
          "&created_end_date<" + context.rootState.filtersStd.dateTo
      }
      if (context.rootState.filtersStd.statusString) {
        filterByString +=
          "&mandarin_status in " + context.rootState.filtersStd.statusString
      }
      if (context.rootState.filtersStd.mandarinId) {
        filterByString +=
          "&mandarin_id=" + context.rootState.filtersStd.mandarinId
      }
      if (context.rootState.filtersStd.providerId) {
        filterByString +=
          "&provider_id=" + context.rootState.filtersStd.providerId
      }

      //очищаем первый &
      if (filterByString && filterByString.charAt(0) === "&") {
        filterByString = filterByString.substring(1)
      }
      // filterByString = encodeURIComponent(filterByString)
      context.commit("filtersStd/updateFilterByString", filterByString, {
        root: true,
      })
    },
    refreshTable(context, queryObject) {
      // if (!VUE_APP_RECEIPTS_URL) let VUE_APP_RECEIPTS_URL = 'https://receipts.stage.psp.io/'
      let url = VUE_APP_RECEIPTS_URL + context.state.urls.receipts

      if (queryObject && queryObject.type === "cursor") {
        url += "?cursor=" + queryObject.params.cursor
      } else {
        //сперва делаем ограничение по limit_to
        let limit = "limit_to=" + queryObject.params.limit_to
        url += "?" + limit

        context.dispatch("prepareFilterByString")

        let filterByString = context.rootState.filtersStd.filterByString
        if (filterByString) {
          url += "&" + "filter_by=" + encodeURIComponent(filterByString)
        }
      }
      this.$axios
        .get(url)
        .then((response) => {
          let cursor = response.data.cursor
          let receipts = response.data.receipts
          context.commit("pagerStd/updateCursor", cursor, { root: true })
          context.commit("pagerStd/updateApiResponseReceived", true, {
            root: true,
          })
          //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
          if (
            typeof queryObject === "object" &&
            queryObject !== null &&
            queryObject.hasOwnProperty("currentOffset")
          ) {
            context.commit(
              "pagerStd/updateCurrentOffset",
              queryObject.currentOffset,
              { root: true }
            )
          } else {
            context.commit("pagerStd/updateCurrentOffset", 0, { root: true })
          }
          context.commit("updateReceipts", receipts)
        })
        .catch((error) => {
          console.log("error in promise", error)
          context.commit("updateReceipts", [])
        })
    },
  },
}
