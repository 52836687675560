export default {
  namespaced: true,
  state: {
    displayTable: {
      tableApiUrls: {
        invoices: {
          url: 'api/v1/invoices/',
          responseObject: 'invoices',
        },
        invoice_transactions: {
          url: 'api/v1/invoices/transactions/',
          responseObject: 'transactions',
        },
      }
    },
    partnersDataReceived: false,
    partner_rewards: [],
    accountSettings: null,
    isLoading: false,
    invoice: null,
    invoiceCopy: null,
    invoices: null,
    invoice_transactions: null,
    error403: false,
  },
  mutations: {
    updateError403(state, value) {  // 403 - недостаточно прав
      state.error403 = value;
    },
    updateinvoices(state, invoices) {
      state.invoices = invoices;
    },
    updateinvoice_transactions(state, transactions) {
      state.invoice_transactions = transactions;
    },
    updatePartnerRewards(state, PRData) {
      state.partner_rewards = PRData;
    },
    togglePartnersDataReceived(state, value) {
      state.partnersDataReceived = value;
    },
    setAccountSettings(state, settings) {
      state.accountSettings = settings;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setInvoice(state, val) {
      state.invoice = val;
    },
    setInvoiceCopy(state, val) {
      state.invoiceCopy = val;
    },
    deleteInvoice(state) {
      state.invoice = null;
    },
  },
  actions: {
    setInvoice({commit}, invoice) {
      commit('setInvoice', invoice);
    },
    clearInvoice({commit}) {
      commit('deleteInvoice');
    },
    async fetchAccountSettings({commit, dispatch, context}, clientId) {
      commit('setLoading', true);
      await this.$axios.get(`${VUE_APP_SECURE_APP}api/v1/settings/${clientId}`, {withCredentials: true})
        .then(response => {
          if (response.status === 404) {
            const newSettings = {
              new: true,
              client_id: clientId,
              is_active: true,
              country_settings: {},
              basic_settings: {
                email: {
                  hide: false,
                  value: ""
                },
                phone: {
                  hide: false,
                  value: ""
                },
                order: {
                  requestPayerOrderId: false,
                  requestPayerEmail: false,
                  requestPayerPhone: false,
                  allowPayerToSpecifyEmailOrPhone: false
                }
              },
              payment_methods_settings: {
                card: {
                  enable: false,
                  merchant_id: "",
                  merchant_name: "",
                },
                card2: {
                  enable: false,
                  merchant_id: "",
                  merchant_name: "",
                },
                credit: {
                  gp: false,
                  term: [],
                  credit: false,
                  enable: false,
                  api_key: "",
                  segment: "",
                  product_id: "",
                  merchant_id: {},
                  merchant: {},
                }
              },
              mandatory_fields_settings: {
                logo: "",
                offer_link: "https://mandarin.io/conditions",
                redirect_url: "https://mandarin.io/",
                redirect_failed_url: "https://mandarin.io/"
              },
              additional_fields_settings: [],
              fiscalization_settings: {
                vat: "None",
                enable: false,
                paymentSubject: "SERVICE",
                taxationSystem: "Common",
                calculationMethod: "FULL_PAY"
              },
              security_settings: {},
              limit_settings: {},
              integration_settings: {},
              advanced_settings: {},
            };
            dispatch('saveAccountSettings', {settings: newSettings});
          } else {
            commit('setAccountSettings', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching account settings:', error);
          if (error.response.status === 403) {
            context.commit('updateError403', true);
          }
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    async saveAccountSettings({commit}, {settings}) {
      commit('setLoading', true);
      const url = `${VUE_APP_SECURE_APP}api/v1/settings/` + (settings.new ? '' : settings.client_id + '/');
      const method = settings.new ? 'post' : 'patch';
      delete settings.new;

      try {
        const response = await this.$axios[method](url, settings, {withCredentials: true});
        commit('setAccountSettings', response.data);
      } catch (error) {
        console.error('Error saving account settings:', error);
        throw error; // Важно: пробрасываем ошибку
      } finally {
        commit('setLoading', false);
      }
    },
    createInvoice({commit, state, context}, invoiceData) {
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        this.$axios.post(`${VUE_APP_SECURE_APP}api/v1/invoices/`, invoiceData, {withCredentials: true})
          .then(response => {
            commit('setInvoice', response.data);
            this._vm.$toast.success('Счет успешно создан');
            resolve(response.data); // Резолвим Promise с данными ответа
          })
          .catch(error => {
            console.error('Ошибка при создании счета:', error);
            this._vm.$toast.error('Ошибка при создании счета: ' + error);
            reject(error); // Реджектим Promise с информацией об ошибке
          })
          .finally(() => {
            commit('setLoading', false);
          });
      });
    },
    prepareFilterByString(context) {
      let filterByString = '';
      if (context.rootState.filtersStd.dateFrom) {
        filterByString += '&from_created_at>=' + context.rootState.filtersStd.dateFrom;
      }
      if (context.rootState.filtersStd.dateTo) {
        filterByString += '&to_created_at<=' + context.rootState.filtersStd.dateTo;
      }
      if (context.rootState.filtersStd.statusString){
        filterByString += '&status in '+context.rootState.filtersStd.statusString
      }
      if (context.rootState.filtersStd.paymentMethod){
        filterByString += '&payment_method in '+context.rootState.filtersStd.paymentMethod
      }
      if (context.rootState.filtersStd.numberOrder){
        filterByString += '&order_number='+context.rootState.filtersStd.numberOrder
      }

      //очищаем первый &
      if (filterByString && filterByString.charAt(0) === '&') {
        filterByString = filterByString.substring(1)
      }
      // filterByString = encodeURIComponent(filterByString)
      context.commit('filtersStd/updateFilterByString', filterByString, {root: true})
    },
    refreshTable(context, queryObject) {
      let activeTableNamespace = context.rootState.pagerStd.activeTableNamespace
      let url = VUE_APP_SECURE_APP + context.state.displayTable.tableApiUrls[activeTableNamespace].url
      let responseObject = context.state.displayTable.tableApiUrls[activeTableNamespace].responseObject

      if (queryObject && queryObject.type === 'cursor') {
        url += '?cursor=' + queryObject.params.cursor
      } else {
        let limit = 'limit_to=' + queryObject.params.limit_to
        url += '?' + limit

        context.dispatch('prepareFilterByString')

        let filterByString = context.rootState.filtersStd.filterByString
        if (filterByString) {
          url += '&' + 'filter_by=' + encodeURIComponent(filterByString)
        }
      }
      this.$axios.get(url, {withCredentials: true})
        .then(response => {
          let cursor = response.data.cursor
          context.commit('pagerStd/updateCursor', cursor, {root: true})
          context.commit('pagerStd/updateApiResponseReceived', true, {root: true})
          //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
          if (typeof queryObject === 'object' && queryObject !== null && queryObject.hasOwnProperty('currentOffset')) {
            context.commit('pagerStd/updateCurrentOffset', queryObject.currentOffset, {root: true})
          } else {
            context.commit('pagerStd/updateCurrentOffset', 0, {root: true})
          }
          context.commit('update'+responseObject, response.data[responseObject])

        })
        .catch(error => {
          console.log('error in promise', error)
          context.commit('update'+responseObject, [])
          if (error.response.status === 403) {
            context.commit('updateError403', true);
          }
        })
    },
    async getBillingPricingModels(context, payload) {
      try {
        const response = await this.$axios.get(`${VUE_APP_BILLING_API}/api/merchant-fees/${payload.clientId}/${payload.merchantId}/actual`);
        const merchantFee = response.data.merchantFee;

        // Проверяем наличие всех необходимых свойств безопасным способом
        if (!merchantFee || !merchantFee.pricingModel || !merchantFee.pricingModel.pricingModelItems) {
          return [];
        }

        // Извлекаем сроки и сортируем их по возрастанию
        const terms = merchantFee.pricingModel.pricingModelItems
          .map(item => {
            const termCondition = item.transactionConditions.find(
              condition => condition.key === 'transaction_info.terminal_info.term'
            );
            return termCondition ? parseInt(termCondition.value) : null;
          })
          .filter(term => term !== null)
          .sort((a, b) => a - b);

        // Формируем массив объектов для селекта
        let options = terms.map(term => ({
          text: `${term}`,
          value: term
        }));

        if (options.length > 1) options.unshift({value: 'none', text: '- не выбрано -'})

        return options;
      } catch (error) {
        console.log('error in promise', error);
        return [];
      }
    },
  },
}
