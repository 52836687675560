<template>
  <b-nav tabs class="tables-top-nav">
    <b-nav-item exact to="/receipts">Чеки</b-nav-item>
    <b-nav-item exact :href="merchant_vue3_counters">Настройки</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  computed: {
    merchant_vue3_counters() {
      return (
        this.removeTrailingSlash(VUE_APP_MERCHANT_VUE3_URL) +
        "/receipts/counters"
      )
    },
  },
}
</script>
