function getStatusBadge(filters_config, current_value){
    if (filters_config !== null) {
        let statusOptions = filters_config.status.options
        let output = '<span class="badge badge-light">'+current_value+'</span>'
        statusOptions.forEach(element => {
          let st = element.value.split(',')
          if (st.includes(current_value)){
            if (element.label === 'Оплачен'){
              output = '<span class="badge badge-success">'+element.label+'</span>'
            }
            if (element.label === 'Ошибка'){
              output = '<span class="badge badge-danger">'+element.label+'</span>'
            }
            if (element.label === 'В процессе' || element.label === 'Частично опл.'){
              output = '<span class="badge badge-warning">'+element.label+'</span>'
            }
            if (element.label === 'Новый'){
              output = '<span class="badge badge-secondary">'+element.label+'</span>'
            }
          }
        })
        return output
    } else {
        return ''
    }
}function getPaymentMethodName(filters_config, current_value){
    if (current_value !== null) {
        let paymentMethodNames = filters_config.paymentMethodNames.options
        let output = '<span class="">'+current_value+'</span>'
      paymentMethodNames.forEach(element => {
        if (element.value === current_value){
          output = '<span class="">'+element.label+'</span>'
        }
        })
        return output
    } else {
        return ''
    }
}
export { getStatusBadge, getPaymentMethodName }
