<template>
  <component v-if="$store.state.account.accessToken" :is="layoutComponent">
    <router-view />
  </component>
  <Loader v-else :size="3" />
</template>

<script>

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function getCurrentDomainWithoutSubdomain() {
      let hostnameArray = window.location.hostname.split('.')
      let domainArray = []
      domainArray.push(hostnameArray.pop())
      while(hostnameArray.length > 1) {
        domainArray.push(hostnameArray.pop())
      }
      domainArray.reverse()
      return domainArray.join('.')
    }
import Default from '@/layouts/Default.vue'
import DefaultMobile from '@/layouts/DefaultMobile.vue'
export default {
  components: {
    Default,
    DefaultMobile
  },
  computed: {
    layoutComponent() {
      return this.mobileComponents.includes(this.$route.name) ? 'DefaultMobile' : 'Default';
    }
  },
  data() {
    return {
      mobileComponents: [
        'Invoices',
        'Projects',
        'Payouts', 'PayoutsForm',
        'Payments', 'PaymentsAll', 'PaymentsChargebacks',
        'LifeBroker', 'LifeBroker_loans'
      ]
    }
  },
  beforeCreate () {
    // В этой точке мы собственно проверяем авторизован наш пользователь или нет.
    // Авторизованный пользователь попадает сюда редиректом с сервера идентити
    // В этом редиректе есть хэш. Мы процессим хэш, проверяем, то ли это что надо.
    // Если хэша нет или он невалидный - посылаем на авторизацию.

    // Второй способ сюда попасть - с сервиса квалификации с access_token в параметре.
    // в таком случае идем другим путём.


    let domainForCookies = '.' + getCurrentDomainWithoutSubdomain()
    let CookieDate = new Date;
    CookieDate.setFullYear(CookieDate.getFullYear() +1)
    let cookieDateFormatted = CookieDate.toUTCString()

    let authorizationHappened = false
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'qualification_token')){
      let data = {}
      data.qualification_token = this.$route.query.qualification_token
      data.user_id = this.$route.query.user_id
      document.cookie = 'qualification_token=' + data.qualification_token + '; expires=' +cookieDateFormatted + ';domain=' + domainForCookies + ';'
      document.cookie = 'qualification_user_id=' + data.user_id + '; expires=' +cookieDateFormatted + ';domain=' + domainForCookies + ';'
      authorizationHappened = true
      this.$store.dispatch('authorizeAndRedirectWithQualificationToken', data)

    }
    let qualification_token = getCookie('qualification_token')
    let qualification_user_id = getCookie('qualification_user_id')
    if(!authorizationHappened && qualification_token !== undefined && qualification_token !== ""){
      let data = {}
      data.qualification_token = qualification_token
      data.user_id = qualification_user_id
      authorizationHappened = true
      this.$store.dispatch('authorizeAndRedirectWithQualificationToken', data)
    }

    if (!authorizationHappened){
      let data = {}
      data.hash = this.$route.hash
      data.router = this.$router
      this.$store.dispatch('authorizeAndRedirect', data)
    }
  }
}
</script>
