export const money = (str) => {
  str = String(str)
  if (str === '0') {
    return '0.00'
  }
  let newvalue = ''
  let roubles = str.split('.')
  if (roubles.length > 1) {
    str = roubles[0]
  }
  let i = 0
  if (str.length > 3) {
    while (str.length > 3) {
      if (i === 0) {
        newvalue = str.substring(str.length - 3, str.length)
      } else {
        newvalue = str.substring(str.length - 3, str.length) + ' ' + newvalue
      }
      i++
      str = str.substring(0, str.length - 3)
    }
    newvalue = str + ' ' + newvalue
  } else {
    newvalue = str + '' + newvalue
  }
  if (roubles.length > 1) {
    newvalue = newvalue + '.' + roubles[1]
  } else {
    newvalue = newvalue + '.00'
  }
  return newvalue + ' ' + '₽'
}

export const moneyExport = (str) => {
  str = String(str)
  if (str === '0') {
    return '0.00'
  }
  let newvalue = ''
  let roubles = str.split('.')
  if (roubles.length > 1) {
    str = roubles[0]
  }
  let i = 0
  if (str.length > 3) {
    while (str.length > 3) {
      if (i === 0) {
        newvalue = str.substring(str.length - 3, str.length)
      } else {
        newvalue = str.substring(str.length - 3, str.length)
      }
      i++
      str = str.substring(0, str.length - 3)
    }
    newvalue = str + '' + newvalue
  } else {
    newvalue = str + '' + newvalue
  }
  if (roubles.length > 1) {
    newvalue = newvalue + ',' + roubles[1]
  }

  return `"${newvalue}"`
}

export const getTermOptions = (merchant, gp) => {
  let options = []
  if (merchant.hl_product === "Гарантированный платеж") {
    gp = true
    let stringArray = merchant.term_setting_steps.split(";");
    options = stringArray.map(item => {
      return {value: Number(item), text: item};
    });
    if (options.length > 1) options.unshift({value: 'none', text: '- не выбрано -'});
  } else {
    if (merchant.settings && merchant.settings.length > 0) {
      options = merchant.settings.map(item => {
        return {value: item.Term, text: item.Term.toString()};
      });
    } else {
      if (merchant.origin_term_setting_steps) {
        let stringArray = merchant.origin_term_setting_steps.split(";");
        options = stringArray.map(item => {
          return {value: Number(item), text: item};
        });
      }
    }
    if (options.length > 1) options.unshift({value: 'none', text: '- не выбрано -'});
  }
  return {options: options, gp: gp};
}
