<template>
  <b-card class="card-small extended-information">
    <h4>Расширенная информация</h4>
    <div v-if="item">
      <div class="w-100 text-left bold my-2">
        Корзина
      </div>
      <b-list-group flush>
        <b-list-group-item style="padding-left: 0 !important;" v-for="(cartItem, index) in item.cart" :key="index" class="d-flex justify-content-between align-items-center">
          <div>
            <div v-if="item.cart.length > 1">{{ index + 1 }}. {{ cartItem.name }}</div>
            <div v-else>{{ cartItem.name }}</div>
            <small>Стоимость: {{ properM(cartItem.cost) }} ₽</small><br>
            <small>НДС: {{ getVatName(cartItem.vat) }}</small>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-form-group class="w-100 mt-5" label="Ссылка на оплату">
        <b-input-group class="w-100">
          <b-form-input class="cursor-pointer" :value="getPaymentUrl()" readonly @click.prevent="goToUrl(getPaymentUrl())"></b-form-input>
          <b-input-group-append>
            <b-button @click="copyUrl" variant="outline-secondary">
              <mdicon name="file-multiple-outline" height="20"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <div v-else class="text-center">
      <mdicon name="information-outline" height="100"/>
      <p>Выберите счет для просмотра</p>
    </div>
  </b-card>
</template>

<script>
const options = [
  { value: 'None', text: 'Без НДС' },
  { value: 'Vat0', text: '0%' },
  { value: 'Vat5', text: '5%' },
  { value: 'Vat7', text: '7%' },
  { value: 'Vat10', text: '10%' },
  // { value: 'Vat18', text: 'НДС по ставке 18%' },
  { value: 'Vat20', text: '20%' }
];
export default {
  props: ['item'],
  components: {

  },
  data() {
    return {

    }
  },
  computed:{

  },
  methods:{
    onCopy(event) {
      event.stopPropagation();
    },
    copyUrl() {
      const urlToCopy = this.getPaymentUrl();
      navigator.clipboard.writeText(urlToCopy).then(() => {
        this.$toast.success('URL успешно скопирован в буфер обмена');
      }).catch(err => {
        this.$toast.error('Не удалось скопировать URL: ', err);
      });
    },
    goToUrl(url) {
      window.open(url , '_blank');
    },
    getVatName(vat) {
      return options.find(item => item.value === vat).text;
    },
    getPaymentUrl() {
      return `${VUE_APP_SECURE_APP}payment/${this.item.id}/`;
    },
  }
}
</script>

<style scoped>
.form-control[readonly]:hover {
  cursor: pointer;
}
</style>
