<template>
  <d-card class="card-div mb-4 life_merchants">
    <d-card-body style="min-height: 500px">
      <Loader v-if="!apiResponseReceived" :size="3" />
      <div v-else>
          <b-form @submit="onSubmit">
            <div class="back">
              <router-link :to="back">
                <img src="@/assets/images/apple-keyboard-control.png" alt="Go back" width="24" height="24">
                <span class="back-link">Вернуться назад</span>
              </router-link>
              <div class="float-right ml-auto">
                <a href="https://mndr.in/nocode_pay_settings" target="_blank">Инструкция по настройке и использованию</a>
              </div>
            </div>
            <div v-show="item.new" class="settings-block">
              <div class="header">
                <div class="header-text">
                  <h3>Тип модуля и проект
                    <i v-b-tooltip.hover title="У вас есть право создавать модули pay (платежи/выплаты), а так же life (кредиты/рассрочки)">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </h3>
                </div>
              </div>
              <b-form-group class="payment-input">
                <legend>Тип модуля
                </legend>
                <b-form-select v-model="selected" :options="computed_options" @input="toogle_action(selected)"></b-form-select>
              </b-form-group>

              <b-form-group class="payment-input mb-0">
                <legend>Merchant_ID</legend>
                <b-form-select
                  :options="enhancedOptions"
                  v-model="selected_mid"
                  @input="toogle_action_mid(selected_mid)"
                  :disabled="isSelectDisabled"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="settings-block">
              <div class="header mb-0">
                <div class="header-text">
                  <h3>Настройки обязательных полей:
                    <i v-b-tooltip.hover title="Вы можете настроить цвет кнопки 'Перейти к оплате', выбрать цвет для фона или разместить фоном картинку, а также добавить свой логотип со ссылкой на свой ресурс.">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </h3>
                </div>
              </div>
              <b-form-checkbox class="payment-input mt-0 mb-0" v-model="item.is_active" name="check-button-is_active" switch>Модуль {{type_name}} активен
                <i v-b-tooltip.hover title="Может быть выключен или включен, соответственно активен и не активен">
                  <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                </i>
              </b-form-checkbox>
              <div v-if="!item.new" class="merchant mt-0 mb-4">
                merchant_id: {{ item.merchant_id }}, {{ $t('extend.site_name') }}: {{ name }}
              </div>

              <b-form-group class="payment-input file-group-container">
                <legend>
                  Логотип мерчанта
                  <i v-b-tooltip.hover title="PNG, JPG, GIF или SVG не более 2 Mb">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                  </i>
                </legend>
                <div class="align-center" v-if="!item.mandatory_info.site.logo">
                  <div class="file-drop-area">
                    <span class="file-message">Загрузите логотип</span>
                    <input class='w-100 file-input' type="file" accept="image/jpeg, image/gif, image/png" id="logoimage" @change="onLogoChange"/>
                  </div>
                </div>
                <div v-else>
                  <div class="align-center" style="max-height: 100px;">
                    <img class='' :src="item.mandatory_info.site.logo"  alt="image"/><br/>
                    <a class="cursor-pointer mt-2" @click='deleteImageLogo()'>
                      <img src="@/assets/images/icon_delete.png" alt="Удалить">
                    </a>
                  </div>
                </div>
              </b-form-group>

              <b-form-group v-if="item.mandatory_info.site.logo" class="payment-input mb-0">
                <legend>Ссылка для логотипа:</legend>
                <b-form-input class="mb-5" v-model="item.mandatory_info.site.logo_url" :value="item.mandatory_info.site.logo_url" />
              </b-form-group>

              <b-form-group class="payment-input mb-0">
                <legend>Цвет фона в формате HEX (#f0f0f0):</legend>
                <b-row class="m-0">
                  <b-col lg='1' class="p-0">
                    <b-form-input class='color2 border-right-0' type="color" v-model="item.mandatory_info.site.bg_color" :value="item.mandatory_info.site.bg_color" />
                  </b-col>
                  <b-col lg='11' class="p-0">
                    <b-form-input class='color1 border-left-0' type="text" v-model="item.mandatory_info.site.bg_color" :value="item.mandatory_info.site.bg_color" />
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="payment-input file-group-container mt-4">
                <legend>
                  Фоновое изображение мерчанта
                  <i v-b-tooltip.hover title="PNG, JPG, GIF или SVG не более 2 Mb">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                  </i>
                </legend>
                <div class="align-center" v-if="!item.mandatory_info.site.bg_image">
                  <div class="file-drop-area">
                    <span class="file-message">Загрузите изображение для фона</span>
                    <input class='w-100 file-input' type="file" accept="image/jpeg, image/gif, image/png" id="bgimage" @change="onBgChange"/>
                  </div>
                </div>
                <div v-else>
                  <div class="align-center" style="max-height: 100px;">
                    <img :src="item.mandatory_info.site.bg_image" alt="image"/>
                    <a class="cursor-pointer mt-2" @click='deleteImageBg()'>
                      <img src="@/assets/images/icon_delete.png" alt="Удалить">
                    </a>
                  </div>
                </div>
              </b-form-group>


              <b-form-group class="payment-input mb-4">
                <legend>Цвет кнопки в формате HEX (#f0f0f0):</legend>
                <b-row class="m-0">
                  <b-col lg='1' class="p-0">
                    <b-form-input class='color2 border-right-0' type="color" v-model="item.mandatory_info.site.button_color" :value="item.mandatory_info.site.button_color" />
                  </b-col>
                  <b-col lg='11' class="p-0">
                    <b-form-input class='color1 border-left-0' type="text" v-model="item.mandatory_info.site.button_color" :value="item.mandatory_info.site.button_color" />
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="payment-input mb-4">
                <legend>Цвет текста кнопки в формате HEX (#ffffff):</legend>
                <b-row class="m-0">
                  <b-col lg='1' class="p-0">
                    <b-form-input class='color2 border-right-0' type="color" v-model="item.mandatory_info.site.button_text_color" :value="item.mandatory_info.site.button_text_color" />
                  </b-col>
                  <b-col lg='11' class="p-0">
                    <b-form-input class='color1 border-left-0' type="text" v-model="item.mandatory_info.site.button_text_color" :value="item.mandatory_info.site.button_text_color" />
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="payment-input mb-4">
                <legend>Название платежной страницы:</legend>
                <b-form-input v-model="item.mandatory_info.site.module_name" :value="item.mandatory_info.site.module_name" />
              </b-form-group>

              <b-form-group class="payment-input mb-4">
                <legend>Валюта:</legend>
                <b-form-input v-model="item.mandatory_info.site.currency" :value="item.mandatory_info.site.currency" />
              </b-form-group>

              <b-form-group class="payment-input mb-4">
                <legend>Выбор цвета Названия платежной страницы в формате HEX (#424242)</legend>
                <b-row class="m-0">
                  <b-col lg='1' class="p-0">
                    <b-form-input class='color2 border-right-0' type="color" v-model="item.mandatory_info.site.module_name_color" :value="item.mandatory_info.site.module_name_color" />
                  </b-col>
                  <b-col lg='11' class="p-0">
                    <b-form-input class='color1 border-left-0' type="text" v-model="item.mandatory_info.site.module_name_color" :value="item.mandatory_info.site.module_name_color" />
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.mandatory_info.order_id_auto" switch>Формировать Номер заказа (order_id) автоматически</b-form-checkbox>

              <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.mandatory_info.order_id_hide" switch>Скрыть поле Номер заказа из формы Приема платежей</b-form-checkbox>

              <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.customer_info.email.hide" switch>Скрыть поле Email из формы Приема платежей</b-form-checkbox>

              <b-form-group class="payment-input mb-0 mt-4">
                <legend>Значение по умолчанию для поля Email</legend>
                <b-form-input v-model="item.customer_info.email.value" :value="item.customer_info.email.value" />
              </b-form-group>

            </div>

            <div class="settings-block">
              <div class="header mb-0">
                <div class="header-text">
                  <h3>Настройки необязательных полей:
                    <i v-b-tooltip.hover title="Указанные значения не обязательны к заполнению">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </h3>
                </div>
              </div>
              <b-form-group class="payment-input mt-0 mb-4">
                <b-form-checkbox v-model="item.customer_info.phone.hide" switch>Скрыть поле Phone из формы Приема платежей</b-form-checkbox>
              </b-form-group>

              <b-form-group class="payment-input mt-0">
                <legend class="">Значение по умолчанию для поля Phone</legend>
                <b-form-input v-model="item.customer_info.phone.value" />
              </b-form-group>

              <b-form-group class="payment-input mb-0">
                <legend>Return URL:
                  <i v-b-tooltip.hover title="В случае успешной оплаты будет предложено перейти по этой ссылке">
                    <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                  </i>
                </legend>
                <b-form-input v-model="item.mandatory_info.site.redirect_url" />
              </b-form-group>

              <b-form-group class="payment-input mb-0">
                <b-form-checkbox v-model="item.mandatory_info.commis" switch>Добавить комиссию сверху суммы платежа</b-form-checkbox>
              </b-form-group>

              <div class="w-100 mt-2" v-if="item.mandatory_info.commis || commis_add">
                <b-form-group class="payment-input">
                  <legend class="">Размер комиссии в % (например, 2.00)</legend>
                  <b-form-input v-model="item.mandatory_info.commisInfo" />
                </b-form-group>
              </div>

            </div>

            <div class="settings-block">
              <div class="header mb-0">
                <div class="header-text">
                  <h3>Настройка формирования чеков
                    <i v-b-tooltip.hover title="Настройки формирования чеков">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </h3>
                </div>
              </div>
              <div class="w-100">
                <b-form-group class="payment-input mb-0">
                  <b-form-checkbox v-model="item.mandatory_info.fiscalEnable" switch>Формировать чек</b-form-checkbox>
                </b-form-group>

                <div v-if="item.mandatory_info.fiscalEnable">
                  <b-form-group class="payment-input mt-4">
                    <legend class="">СН:</legend>
                    <b-form-select v-model="item.mandatory_info.fiscalInformation.taxationSystem" :options="optionsTaxationSystem"></b-form-select>
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Ставка НДС:</legend>
                    <b-form-select v-model="item.mandatory_info.fiscalInformation.vat" :options="optionsVat"></b-form-select>
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Наименование:</legend>
                    <b-form-input v-model="item.mandatory_info.fiscalInformation.description" />
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Способ расчета:</legend>
                    <b-form-select v-model="item.mandatory_info.fiscalInformation.calculationMethod" :options="optionscalculationMethod"></b-form-select>
                  </b-form-group>

                  <b-form-group class="payment-input">
                    <legend class="">Предмет расчета:</legend>
                    <b-form-select v-model="item.mandatory_info.fiscalInformation.paymentSubject" :options="optionspaymentSubject"></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="settings-block">
              <div class="header mb-0">
                <div class="header-text">
                  <h3>Настройка дополнительных полей:
                    <i v-b-tooltip.hover title="Данные поля передаются по API и отображаются в Форме выплаты, а также в реестрах транзакций">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </h3>
                </div>
              </div>
              <div>
                <b-form-checkbox class="payment-input" v-model="ext_field_visible" switch>Добавить дополнительные поля</b-form-checkbox>
                <div v-show="ext_field_visible">
                  <b-table :fields="fields_optional_info" :items="item.optional_info" class="w-100">
                    <template #cell(key)="data">
                      <b-form-input v-model="data.item.key" :value="data.item.key"/>
                    </template>
                    <template #cell(value)="data">
                      <b-form-input v-model="data.item.value" :value="data.item.value"/>
                    </template>
                    <template #cell(delete)="data">
                      <div class="d-flex justify-content-end align-items-center">
                        <img :src="require('@/assets/images/icon_delete.png')" alt="delete icon" class="lh-36" @click='del(data.index)' />
                      </div>
                    </template>
                  </b-table>
                  <b-button block variant="outline-primary" @click='add()'>Добавить поле</b-button>
                </div>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-12">
                <b-button :disabled="!enableSaveModule" type="submit" variant="primary" block>Сохранить</b-button>
              </div>
            </div>
          </b-form>
      </div>
    </d-card-body>
  </d-card>
</template>
<style>
    .lh-36 {
        line-height: 36px
    }
    .align-center {
        text-align: center
    }
    .cursor-pointer {cursor: pointer; display: block;}
    .file-drop-area {
      position: relative;
      width: 400px;
      max-width: 400px;
      padding: 25px;
      border: 1px dashed rgba(0, 0, 0, 0.4);
      border-radius: 3px;
      transition: 0.2s;
    }
    .file-message {
      font-size: small;
      font-weight: 300;
      line-height: 1.4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;
    }
</style>
<script>
export default {
  data () {
      return {
        commis_add: false,
        optionsTaxationSystem: [
            {value: 'Common', text: 'Общая (ОСН)'},
            {value: 'Simplified', text: 'Упрощенная (УСН) "Доходы"'},
            {value: 'SimplifiedMinusOutlay', text: 'Упрощенная (УСН) "Доходы минус расходы"'},
            {value: 'UnifiedImputedIncome', text: 'Единый налог на вмененный доход (ЕНВД)'},
            {value: 'UnifiedAgricultural', text: 'Единый сельскохозяйственный налог (ЕСХН)'},
            {value: 'Patent', text: 'Патентная (ПСН)'}
        ],
        optionsVat: [
            {value: 'None', text: 'Без НДС'},
            {value: 'Vat0', text: 'НДС по ставке 0%'},
            {value: 'Vat5', text: 'НДС по ставке 5%'},
            {value: 'Vat7', text: 'НДС по ставке 7%'},
            {value: 'Vat10', text: 'НДС по ставке 10%'},
            //{value: 'Vat18', text: 'НДС по ставке 18%'},
            {value: 'Vat20', text: 'НДС по ставке 20%'}
        ],
        optionscalculationMethod: [
            {value: 'FULL_PAY', text: 'Полная оплата, в том числе с учётом аванса (предварительной оплаты) в момент передачи предмета расчёта'},
            {value: 'PREPAY_FULL', text: 'Полная предварительная оплата до момента передачи предмета расчёта'},
            {value: 'PREPAY_PARTIAL', text: 'Частичная предварительная оплата до момента передачи предмета расчёта'},
            {value: 'AVANS', text: 'Аванс'},
            {value: 'PARTIAL_SETTLEMENT_AND_CREDIT', text: 'Частичная оплата предмета расчёта в момент его передачи с последующей оплатой в кредит'},
            {value: 'TRANSFER_ON_CREDIT', text: 'Передача предмета расчёта без его оплаты в момент его передачи с последующей оплатой в кредит'},
            {value: 'CREDIT_PAYMENT', text: 'Оплата предмета расчёта после его передачи с оплатой в кредит (оплата кредита)'}
        ],
        optionspaymentSubject: [
          {value: 'PRODUCT', text: 'Товар'},
          {value: 'SERVICE', text: 'Услуга'},
          {value: 'AGENCY', text: 'Агентское вознаграждение'},
          {value: 'COMPOUND_SUBJECT', text: 'Составной предмет расчета'},
          {value: 'EXCISABLE_PRODUCT', text: 'Подакцизный товар'},
          // {value: 'GAMBLING_RATE', text: 'Ставка азартной игры'},
          // {value: 'GAMBLING_WIN', text: 'Выигрыш азартной игры'},
          // {value: 'INSURANCE_CONTRIBUTIONS', text: 'Страховые взносы'},
          {value: 'JOB', text: 'Работа'},
          // {value: 'LOTTERY_TICKET', text: 'Лотерейный билет'},
          // {value: 'LOTTERY_WIN', text: 'Выигрыш лотереи'},
          // {value: 'NON_OPERATING_INCOME', text: 'Внереализационный доход'},
          {value: 'OTHER_SUBJECT', text: 'Иной предмет расчета'},
          {value: 'PAYMENT', text: 'Платеж'},
          {value: 'PLEDGE', text: 'Залог'},
          // {value: 'PROPERTY_LAW', text: 'Имущественное право'},
          // {value: 'PROVISION_RID', text: 'Представление РИД'},
          {value: 'RESORT_FEE', text: 'Курортный сбор'},
          {value: 'TRADE_FEE', text: 'Торговый сбор'},
        ],
        merchants_pay: [],
        merchants_payout: [],
        fields_optional_info: [{key: 'key', label: 'Name (название поля)'}, {key: 'value', label: 'Value (значение поля)'}, {key: 'delete', label: '', class: 'align-center'}],
        options: [{value: 'payout', text: 'Выплаты'}, {value: 'pay', text: 'Приём платежей'}, {value: 'life', text: 'Кредиты/Рассрочки'}],
        selected: null,
        selected_mid: null,
        ext_field_visible: false,
      };
  },
  computed: {
    isSelectDisabled() {
      return !this.computedMerchantsPayForSelect || this.computedMerchantsPayForSelect.length === 0;
    },
    enhancedOptions() {
      let options = [...this.computedMerchantsPayForSelect];

      if (this.isSelectDisabled) {
        options.unshift({
          text: "У вас нет доступных merchant_id для создания модуля",
          value: null,
          disabled: true
        });
      } else if (!this.enableSaveModule) {
        options.unshift({
          text: "Выберите Merchant",
          value: null,
          disabled: true
        });
        this.selected_mid = null;
      }

      return options;
    },
    computed_merchants_pay() {
      return this.get_all_merchants.filter(m => !this.merchants_pay.includes(m) && this.addModulePayScope);
    },
    computed_merchants_payout() {
      return this.get_all_merchants.filter(m => !this.merchants_payout.includes(m) && this.addModulePayoutScope);
    },
    computed_options() {
      let options = [...this.options];
      if (!this.addModulePayScope) options = options.filter(item => item.value !== 'pay');
      if (!this.addModulePayoutScope) options = options.filter(item => item.value !== 'payout');
      if (!this.addModuleLifeScope) options = options.filter(item => item.value !== 'life');
      return options;
    },
    type_name: function () {
      return (this.selected === 'payout')?'выплат':'приема платежей';
    },
    computedMerchantsPay() {
      const merchants = this.get_all_merchants;
      const dataTable = this.getDataTable();
      const merchants_pay = [];
      dataTable.forEach(m => {if (m.action === 'pay' && this.addModulePayScope) {merchants_pay.push(m.merchant_id);}});
      return merchants.filter(m => !merchants_pay.includes(m));
    },
    computedMerchantsPayForSelect() {
      const merchants = this.get_all_merchants;
      const merchants_name = this.$store.state.account.merchants_name;
      const dataTable = this.getDataTable();
      const merchants_pay = [];
      dataTable.forEach(m => {
        if (m.action === 'pay' && this.addModulePayScope) {
          merchants_pay.push(m.merchant_id);
        }
      });
      return merchants
        .filter(m => !merchants_pay.includes(m))
        .map(m => ({
          value: m,
          text: m + ' (' + merchants_name[merchants.indexOf(m)] + ')'
        }));
    },
    computedMerchantsPayout() {
      const merchants = this.get_all_merchants;
      const dataTable = this.getDataTable();
      const merchants_payout = [];
      dataTable.forEach(m => {if (m.action === 'payout' && this.addModulePayoutScope) {merchants_payout.push(m.merchant_id);}});
      return merchants.filter(m => !merchants_payout.includes(m));
    },
    addModulePayScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_pay_module.write');
    },
    addModulePayoutScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_payout_module.write');
    },
    addModuleLifeScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_life_module.write');
    },
    get_all_merchants () {
      return this.$store.state.account.merchants
    },
    name() {
        return this.$store.state.account.merchants_name[this.$store.state.account.merchants.indexOf(parseInt(this.$route.params.mid))];
    },
    back () {
        return '/pay_merchant/' + this.$route.params.arg1 + '/' + 'modules/';
    },
    apiResponseReceived () {
      return this.$store.state.pager.apiResponseReceived;
    },
    enableSaveModule () {
      return this.get_all_merchants.includes(Number(this.$route.params.mid))
    },
    item () {
      let item = {
          new: true,
          is_active: true,
          action: 'pay',
          merchant_id: this.$route.params.mid,
          mandatory_info: {
                  order_id_auto: true,
                  order_id_hide: true,
                  site: {
                      currency: "₽",
                      module_name: 'Прием платежей',
                      logo: "",
                      logo_url: "",
                      bg_color: "#ffffff",
                      bg_image: "",
                      button_color: "#2e6da4",
                      module_name_color: "#424242",
                      button_text_color: "#ffffff"
                  },
                  fiscalEnable: false,
                  fiscalInformation: {
                    calculationMethod: "FULL_PAY",
                    paymentSubject: "SERVICE",
                    taxationSystem: "Common",
                    vat: "None",
                    description: "Товар"
                  },
                  commis: false,
                  commisInfo:"0"
          },
          customer_info: {
                phone: {
                    hide: true,
                    value: ""
                },
                email: {
                    hide: true,
                    value: ""
                }
          },
          optional_info: [
                    {
                    key: '',
                    value: ''
                    }
          ]
      }
      let dataTable = this.getDataTable()
      this.commis_add = false
      dataTable.forEach( (field, j) => {
        if (field.hasOwnProperty('merchant_id')) {
          if (field.merchant_id === Number(this.$route.params.mid) && field.action === this.$route.params.arg3) item.new = j
          if (field.merchant_id === Number(this.$route.params.mid) && field.action === 'pay') {
              if (field.mandatory_info === null) dataTable[j].mandatory_info = item.mandatory_info
              if (field.optional_info === null || field.optional_info.length < 1 || field.optional_info[0] === null) dataTable[j].optional_info = item.optional_info; else this.ext_field_visible = true;
              if (field.customer_info === null) dataTable[j].customer_info = item.customer_info
              let i = field.mandatory_info.site
              if (i.logo && !i.logo.includes('base64') && !i.logo.includes('://')) i.logo = VUE_APP_SECURE_APP+'images/'+i.logo;
              if (i.bg_image && !i.bg_image.includes('base64') && !i.bg_image.includes('://')) i.bg_image = VUE_APP_SECURE_APP+'images/'+i.bg_image;
              if (i.bg_color === '') i.bg_color = item.mandatory_info.site.bg_color;
              if (i.button_color === '') i.button_color = item.mandatory_info.site.button_color;
              if (i.module_name_color === '') i.module_name_color = item.mandatory_info.site.module_name_color;
              if (i.button_text_color === '') i.button_text_color = item.mandatory_info.site.button_text_color;
              dataTable[j].mandatory_info.site = i
              if (field.is_active === null)
                  dataTable[j].is_active = item.is_active
              if (!field.mandatory_info.hasOwnProperty('commis')) {
                  this.commis_add = true
                  dataTable[j].mandatory_info.commis = item.mandatory_info.commis
                  dataTable[j].mandatory_info.commisInfo = item.mandatory_info.commisInfo
                }
          }
        }
      })
      if (item.new === true) {
              let len = dataTable.push(item)
              return dataTable[len-1]
      } else {
          return dataTable[item.new]
      }
    }
  },
  methods: {
    getDataTable() {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace;
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].
        displayTable.tableApiUrls[activeColumnRow].responseObject;
      return this.$store.state[activeTableNamespace].apiData[responseObject];
    },
      onSubmit(evt) {
        evt.preventDefault();
        if (this.item.customer_info.email.hide === true) {
            if (this.item.customer_info.email.value === '') {
                this.$toast.error("Укажите Email в Обязательных параметрах, если хотите скрыть это поле в форме выплат")
                return
            }
            if (!(/^[a-z0-9_\-\.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/.test(this.item.customer_info.email.value))) {
                this.$toast.error("Указан невалидный EMail")
                return
            }
        }
        if (this.item.customer_info.phone.hide === true) {
            if (this.item.customer_info.phone.value === '') {
                this.$toast.error("Укажите номер телефона в Необязательных параметрах, если хотите скрыть это поле в форме выплат")
                return
            }
            if (!(/^(\+7)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(this.item.customer_info.phone.value))) {
                this.$toast.error("Указан невалидный номер телефона")
                return
            }
        }
        let newOI = [];
        let OI = this.item;
        for (let i in OI.optional_info) {
            console.log(JSON.stringify(OI.optional_info[i].key));
            if (OI.optional_info[i].key !== '') {
                newOI.push(OI.optional_info[i]);
            }
        }
        OI.optional_info = newOI;
        if (OI.mandatory_info.order_id_auto !== true) OI.mandatory_info.order_id_hide = false;
        this.$store.dispatch('payLender/saveEditPayout', {item: OI, back: this.back, router: this.$router});
      },
      add() {
          this.item.optional_info.push({
                    'key': '',
                    'value': ''
                    });
      },
      del(index) {
          this.item.optional_info.splice(index, 1);
      },
    toogle_action(value) {
      let dataTable = this.getDataTable()
      dataTable.pop()
      let mid = this.$route.params.mid
      if (value === 'life') {
        mid = 'new'
      } else {
        let validate_merchants = (value === 'pay')?this.computedMerchantsPay:this.computedMerchantsPayout;
        if (!validate_merchants.includes(mid)) {
          mid = validate_merchants[0];
        }
      }
      this.$router.push('/pay_merchant/'
        + this.$route.params.arg1
        + '/' + this.$route.params.arg2
        + '/' + value
        + '/' + mid + '/')
    },
    toogle_action_mid(value) {
      this.$router.push('/pay_merchant/'
        + this.$route.params.arg1
        + '/' + this.$route.params.arg2
        + '/' + this.$route.params.arg3
        + '/' + value + '/')
    },
      deleteImageLogo() {
          this.item.mandatory_info.site.logo = "";
      },
      deleteImageBg() {
          this.item.mandatory_info.site.bg_image = "";
      },
      onLogoChange: function(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0], true);
            },
      onBgChange: function(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0], false);
            },
      createImage: function(file, lg) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = function(e) {
                    if (lg) {
                        vm.item.mandatory_info.site.logo = e.target.result;
                    } else {
                        vm.item.mandatory_info.site.bg_image = e.target.result;
                    }
                };
                reader.readAsDataURL(file);
            },
  },
  mounted () {
    if (!this.addModulePayScope) {
      this.$router.push('/pay_merchant/'
        + this.$route.params.arg1
        + '/' + this.$route.params.arg2
        + '/' + this.$route.params.arg3
        + '/'
      )
    }
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pager/updateCurrentTableState', 'default');
    this.$store.dispatch('extend/changedTable');
  },
  created () {
    this.selected = this.$route.params.arg3;
    this.selected_mid = this.$route.params.mid;
  },
  watch: {
  },
}
</script>
