<template>
  <b-nav tabs class="tables-top-nav">
    <!--<b-nav-item to="/settings/profile">{{ $i18n.t('navigation.questionnaire') }}</b-nav-item>-->
    <!--<b-nav-item to="/settings/security">{{ $i18n.t('navigation.security') }}</b-nav-item>-->
    <!--<b-nav-item to="/settings/users">{{ $i18n.t('navigation.questionnaire') }}</b-nav-item>-->
    <b-nav-item  to="/settings/requisites">{{ $i18n.t('navigation.details') }}</b-nav-item>
<!--    <b-nav-item to="/settings/notifications">Уведомления</b-nav-item>-->
    <b-nav-item v-if="checkScopePresence('lead_reward_certificates.read')" to="/rewards">{{ $i18n.t('navigation.reward') }}</b-nav-item>
  </b-nav>
</template>

<script>
export default {

}
</script>
