<template>
  <b-row>
    <b-col>
      <nav-tabs />
      <b-card class="card-small with-nav-tabs">
        <h5 class="pt-2">Новый банковский счет</h5>
        <hr />

        <b-row>
          <b-col>
            <label for="account_number">Расчетный счет</label>
            <b-input
              id="account_number"
              type="number"
              v-on:keyup="validateAccountNumberLength()"
              name="account_number"
              v-model="account_number"
              :state="accountNumberState"
              placeholder="Введите номер банковского счета"
            />
          </b-col>
          <b-col>
            <label>БИК банка</label>
            <VueBicSuggestions
              v-bind:bic="bic"
              v-on:update:bic="bic = $event"
              v-on:suggestionSelected="suggestionSelected($event)"
              :placeholder="'Введите БИК'"
              class="form-control"
              :notRewardsModule="true"
            >
            </VueBicSuggestions>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <label for="bankname">Наименование банка</label>
            <b-input id="bankname" name="bankname" :value="bankname" disabled />
          </b-col>
          <b-col>
            <label for="bankcity">Город банка получателя</label>
            <b-input id="bankcity" name="bankcity" :value="bankcity" disabled />
          </b-col>
          <b-col>
            <label for="correspondent_account">К/с банка</label>
            <b-input
              id="correspondent_account"
              name="correspondent_account"
              :value="correspondent_account"
              disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="outline-secondary"
              class="mr-4"
              to="/settings/requisites"
              >Назад</b-button
            >
            <b-button
              variant="outline-primary"
              @click="addBankAccount"
              :disabled="!allowedSubmit"
              >Добавить банковский счет</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
function defineClientType(clientData) {
  if (!Object.prototype.hasOwnProperty.call(clientData, "legal_info")) {
    return false
  }
  if (clientData.legal_info.legal_type === "individual_rus") {
    return "selfemployed"
  }
  if (clientData.legal_info.legal_type === "business_rus") {
    if (clientData.legal_info.legal_data.opf.short === "ИП") {
      return "business_ip"
    } else {
      return "business"
    }
  }
  return false
}
let bankAccountString = {
  business_ip: ["40802"],
  business: [
    "40701",
    "40702",
    "40703",
    "40704",
    "40705",
    "40706",
    "40707",
    "40708",
    "40709",
    "40710",
    "40711",
    "40712",
    "40713",
    "40714",
    "40715",
    "40716",
    "40717",
    "40718",
    "40719",
    "40720",
    "40721",
    "40722",
    "40723",
    "40728",
    "40729",
    "40501",
    "40601",
    "40101",
    "40102",
  ],
  selfemployed: [
    "40817",
    "40820",
    "40821",
    "40822",
    "40823",
    "40824",
    "40825",
    "40826",
    "40827",
    "40828",
    "40829",
  ],
}
import VueBicSuggestions from "@/components/Rewards/rewardsBicSuggestions"
import NavTabs from "./navTabs"
export default {
  components: {
    VueBicSuggestions,
    NavTabs,
  },
  data() {
    return {
      bic: "",
      bankname: "",
      bankcity: "",
      correspondent_account: "",
      account_number: "",
      accountNumberState: null,
      full_bank_data: {},
    }
  },
  computed: {
    allowedSubmit() {
      this.accountNumberState = null
      if (this.bic == "" || this.account_number == "") {
        return false
      }
      if (this.account_number.length != 20) {
        return false
      }
      if (this.$store.state.bankAccounts.busy) {
        return false
      }
      // Проверяем по алгоритму правильность расчетного счета.
      var bikRs = this.bic.toString().slice(-3) + this.account_number
      var checksum = 0
      var coefficients = [
        7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
      ]
      for (var i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10)
      }
      if (checksum % 10 === 0) {
        this.accountNumberState = true
      } else {
        this.accountNumberState = false
      }
      if (!this.accountNumberState) {
        return false
      }
      // Далее нам надо проверить на соответствие номера счета юридическому лицу.
      if (
        defineClientType(this.$store.state.account.clientData) === "business_ip"
      ) {
        if (
          !bankAccountString.business_ip.includes(
            this.account_number.substring(0, 5)
          )
        ) {
          this.accountNumberState = false
          this.$toast.error(
            "Некорректный номер расчетного счета. Введите расчетный счет для индивидуального предпринимателя."
          )
        }
      }
      if (
        defineClientType(this.$store.state.account.clientData) === "business"
      ) {
        if (
          !bankAccountString.business.includes(
            this.account_number.substring(0, 5)
          )
        ) {
          this.accountNumberState = false
          this.$toast.error(
            "Некорректный номер расчетного счета. Введите расчетный счет для юридического лица."
          )
        }
      }
      if (
        defineClientType(this.$store.state.account.clientData) ===
        "selfemployed"
      ) {
        if (
          !bankAccountString.selfemployed.includes(
            this.account_number.substring(0, 5)
          )
        ) {
          this.accountNumberState = false
          this.$toast.error(
            "Некорректный номер расчетного счета. Введите расчетный счет для физического лица."
          )
        }
      }
      if (!this.accountNumberState) {
        return false
      }

      return true
    },
  },
  methods: {
    suggestionSelected(suggestionData) {
      this.bankname = suggestionData.suggestion.value
      ;(this.bankcity = suggestionData.suggestion.data.payment_city),
        (this.correspondent_account =
          suggestionData.suggestion.data.correspondent_account)
      this.full_bank_data = suggestionData.suggestion
    },
    validateAccountNumberLength() {
      if (this.account_number.length > 20) {
        this.account_number = this.account_number.slice(0, 20)
      }
    },
    addBankAccount() {
      let data = {}
      let payload = {
        country:
          this.$store.state.account.clientData.country.iso_country_name_a3,
        bic: this.bic,
        bank_data: this.full_bank_data,
        account_number: this.account_number,
        description: "",
        source: "dadata",
      }

      data.router = this.$router
      data.payload = payload
      this.$store.dispatch("bankAccounts/addNewBankAccount", data)
    },
  },
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
